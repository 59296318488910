import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'dadyin-searchable-select',
  templateUrl: './dadyin-searchable-select.component.html',
  styleUrls: ['./dadyin-searchable-select.component.scss']
})
export class DadyinSearchableSelectComponent implements OnInit {

  @Input() label: any;
  @Input() fullItems: any;
  @Input() bindValue:any='id';
  @Input() bindLabel:any='description';
  @Input() control:any=new FormControl();
  @Input() placeholder:any='';
  @Output() changed:any=new EventEmitter();
  @Input() isDisabled:any=false;
  @Input() multiple =false
  constructor() { }

  ngOnInit(): void {
  }

  customSearchFn= (term: string, item: any): boolean =>{
   const commaseparatedword:string[]= term.split(',')
   const spacesepartedword:string[]=commaseparatedword[0].split(' ')
   const finalkeywords:string[]=[]
   spacesepartedword.forEach(element => {
    finalkeywords.push(element + (commaseparatedword[1] ? ' ' + commaseparatedword[1] : ''))   
   });
   return finalkeywords.some(element=>item[this.bindLabel]?.toLowerCase().includes(element?.toLowerCase()))
  }

  onChange() {
    this.changed.emit(this.control?.value)
  }


}
