import {  HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {  map, Observable } from 'rxjs';
import { Attribute, AttributeResponse } from '../model/common/attribute.model';
import {
    BusinessAccount,
    BusinessAccountResponse,
} from '../model/common/business-account';
import { HSNCode, HSNCodeResponse } from '../model/common/hsncode.model';
import { Process, ProcessResponse } from '../model/common/processes.model';
import {
    ProductListResponse,
    Products,
} from '../model/common/product-list.model';
import {
    ProdTemplate,
    ProdTemplateResponse,
} from '../model/common/product-template.model';
import { Role, RoleResponse } from '../model/common/role';
import {
    CalculatorMeta,
    CalculatorMetaResponse,
} from '../model/product-template/calculator-meta';
import { SuccessDialogComponent } from '../shared/dialogs/succes/success-dialog.componet';
import { AttributeGroupResponse } from '../shared/model/product-template/attribute-group.model';
import { AttributeListResponse } from '../shared/model/product-template/attribute-list.model';
import { ConversionCostResponse } from '../shared/model/product-template/conversion-cost.model';
import { ProcessListResponse } from '../shared/model/product-template/process-list.model';
import { ProductTableResponse } from '../shared/model/product-template/product-table.model';
import {
    ProductType,
    ProductTypeResponse,
} from '../model/product-template/product-type';
import { withCache } from '@ngneat/cashew';
import { AttributeGroup } from '../model/product-template/attribute-group.model';
import {
    ConversionType,
    ConversionTypeResponse,
} from '../model/product-template/conversion-cost.model';
import { HttpService } from './http.service';
import { apiModules, rfqAPIs } from '../shared/constant';
import { AuthService } from './auth.service';
import { GenricResponse } from '../model/common/generic-response';
@Injectable({
    providedIn: 'root',
})
export class CommonApiService {
    url = 'assets/mock/';

    constructor(
        private httpService: HttpService,
        private dialog: MatDialog,
        private authService: AuthService
    ) { }

    getAttributeGroup(): Observable<AttributeGroup[]> {
        return this.httpService
            .get<AttributeGroupResponse>(`${apiModules.attribute_groups}`, {
                context: withCache({
                    mode: 'stateManagement',
                }),
            })
            .pipe(map((data) => data.results));
    }

    getAttributes(): Observable<Attribute[]> {
        return this.httpService
            .get<AttributeResponse>(`${apiModules.attribute}`, {
                context: withCache({
                    mode: 'stateManagement',
                }),
            })
            .pipe(map((attribute) => attribute.content));
    }

    getProductTemplate(): Observable<ProdTemplate[]> {
        return this.httpService
            .get<ProdTemplateResponse>(`${apiModules.product_template}`)
            .pipe(map((prodTemp) => prodTemp.results));
    }

    getProductTemplateNotIsPackageEntity(): Observable<ProdTemplate[]> {
        return this.httpService
            .get<ProdTemplateResponse>(
                `${apiModules.product_template}?is_package_entity=False`
            )
            .pipe(map((prodTemp) => prodTemp.results));
    }

    getProcess(): Observable<Process[]> {
        return this.httpService
            .get<ProcessResponse>(`${apiModules.processes}`)
            .pipe(map((process) => process.results));
    }

    getProductsList(): Observable<Products[]> {
        return this.httpService
            .get<ProductListResponse>(`${apiModules.productList}`)
            .pipe(map((data) => data.content));
    }

    getServerError(errorMsg: string): void {
        let error = errorMsg ? errorMsg : 'Something went wrong';
        this.dialog.open(SuccessDialogComponent, {
            data: { type: 'error', title: error },
        });
    }

    getCalculatorMeta(): Observable<CalculatorMeta[]> {
        return this.httpService
            .get<CalculatorMetaResponse>(`${apiModules.calculator_meta}`, {
                context: withCache({
                    mode: 'stateManagement',
                }),
            })
            .pipe(map((data) => data.results));
    }

    getHSNCode(): Observable<HSNCode[]> {
        return this.httpService
            .get<HSNCodeResponse>(`${apiModules.hsn_code}`)
            .pipe(map((data) => data.results));
    }

    getBusinessAccount(): Observable<BusinessAccount[]> {
        return this.httpService
            .get<BusinessAccountResponse>(`${apiModules.business_account}`)
            .pipe(map((data) => data.results));
    }

    getBusinessAccountByUserId(): Observable<BusinessAccount[]> {
        let userId = this.authService.$currentUser.value.id;
        return this.httpService.get<BusinessAccount[]>(
            `${apiModules.load_business_account}` + userId
        );
    }

    sendInvite(inviteDetail): Observable<GenricResponse> {
        let userId = this.authService.$currentUser.value.id;
        inviteDetail.userId = userId;

        return this.httpService.post<GenricResponse>(
            `${apiModules.send_invite}`,
            inviteDetail,
            false
        );
    }

    getRoles(): Observable<Role[]> {
        return this.httpService
            .get<RoleResponse>(`${apiModules.role}`)
            .pipe(map((data) => data.results));
    }

    getProductType(): Observable<ProductType[]> {
        return this.httpService
            .get<ProductTypeResponse>(`${apiModules.product_type}`, {
                context: withCache({
                    mode: 'stateManagement',
                }),
            })
            .pipe(map((data) => data.results));
    }

    getConversionType(): Observable<ConversionType[]> {
        return this.httpService
            .get<ConversionTypeResponse>(`${apiModules.conversion_type}`, {
                context: withCache({
                    mode: 'stateManagement',
                }),
            })
            .pipe(map((data) => data.results));
    }

    getProductsWithFilters(
        filters: { key: string; value: string }[]
    ): Observable<Products[]> {
        let params = new HttpParams();
        for (const filter of filters) {
            params = params.append(filter.key, filter.value);
        }
        return this.httpService
            .get<ProductListResponse>(`${apiModules.productList}`, { params: params })
            .pipe(map((data) => data.content));
    }

    deleteProcessById(id: number): Observable<any> {
        return this.httpService.delete<any>(`${apiModules.processes}${id}/`).pipe(
            map(() => {
                return true;
            })
        );
    }

    updateProductCalc(product: Products): Observable<Products> {
        return this.httpService.post<Products>(
            `${apiModules.prod_calculation}`,
            product
        );
    }

    handleError(err): void {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${err.error.message}`;
        } else {
            // server-side error
            if (
                err.error.hasOwnProperty('description') &&
                Array.isArray(err.error.description) &&
                err.error.description.length
            ) {
                errorMessage = err.error.description[0];
            } else if (err.error.hasOwnProperty('detail')) {
                errorMessage = err.error.detail;
            } else {
                errorMessage = `Server Side Error. Error Code: ${err.status}`;
            }
        }
        this.getServerError(errorMessage);
    }

    /**********************************/
    getAttributeGroups(): Observable<AttributeGroupResponse> {
        return this.httpService.get<AttributeGroupResponse>(
            `${this.url}attribute-groups.json`
        );
    }

    getProductTypes(): Observable<ProductTypeResponse> {
        return this.httpService.get<ProductTypeResponse>(
            `${this.url}product-type.json`
        );
    }

    getAttributeList(): Observable<AttributeListResponse> {
        return this.httpService.get<AttributeListResponse>(
            `${this.url}attribute-list.json`
        );
    }

    /*     getAttributes(): Observable<AttributesResponse> {
              return this.httpService.get<AttributesResponse>(`${this.url}attributes.json`);
          } */

    getProcessList(): Observable<ProcessListResponse> {
        return this.httpService.get<ProcessListResponse>(
            `${this.url}process-list.json`
        );
    }

    getConversionCost(): Observable<ConversionCostResponse> {
        return this.httpService.get<ConversionCostResponse>(
            `${this.url}conversion-cost.json`
        );
    }

    getTableData(): Observable<ProductTableResponse> {
        return this.httpService.get<ProductTableResponse>(
            `${this.url}table-data.json`
        );
    }

    getTypeOfNotes(): Observable<any> {
        return this.httpService.get<any>(`${rfqAPIs.type_of_notes}`);
    }
}
