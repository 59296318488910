// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dadyin-staging.azurewebsites.net/',
  calcUrl: 'https://staging.dadyin.com/calc/',
  authApiUrl: 'https://dadyin-staging-auth.azurewebsites.net/',
  uiURL: 'https://staging.dadyin.com/',
  stripePK: 'pk_live_51Mle3EKVGM9t2hrt9aNqrpzUp7FjMweXcyZ3WFFCla8YXr2L6paTdJkN661cqOdbO9nRHLLrd1xETwSM9Ah4TleO00gNDRWnyP',
  imgUrl: 'https://dadyinstorage.blob.core.windows.net/staging/',
    guestQuickCheckOutToken:"eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5OTk5QkE5OTk5IiwiaWF0IjoxNjk3NDMxNzU3fQ.7KfrHu7pcX_UHH9yOR_cccjud711CANKwoRUCxKMlIdF0n8XkWfhS2ylEarzYdeEGxKbzFph5idElTRsm0Mn4w"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
