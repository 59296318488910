<div class="dialog-container">
  <h3 mat-dialog-title>Are you sure want to delete?</h3>

  <div mat-dialog-actions class="justify-content-center">
    <button
      mat-raised-button
      color="warn"
      class="delete-btn"
      [mat-dialog-close]="1"
    >
      Delete
    </button>
    <button mat-raised-button (click)="cancel()" tabindex="-1">Cancel</button>
  </div>
</div>
