<div class="p-2">
  <div class="d-flex justify-content-between my-1">
    <div></div>
    <div class="title-text">Give Rating</div>
    <div>
      <dadyin-button type='image' [typeval]="'assets/img/icons/close.svg'" class="iconCen" (click)="close()"
      [theme]="'borderless-primary'">
     </dadyin-button>
    </div>    
  </div>
  <div class="d-flex justify-content-center w-100 my-1 title-text">
    <span *ngFor="let star of [1,2,3,4,5]; let i = index" (click)="rate(star)">
      <mat-icon class="star-icon-size primary-text" *ngIf="star <= rating">star</mat-icon>
      <mat-icon class="star-icon-size  primary-text" *ngIf="star > rating">star_outline
      </mat-icon>
    </span>
  </div>
  <div  class="d-flex justify-content-center my-2">
    <dadyin-button
    label="Save Changes" (click)="save()" [theme]="'secondary'">
    </dadyin-button>
  </div>
</div>