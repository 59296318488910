import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'qcmobile-dialog',
  templateUrl: './qcmobile-dialog.componet.html',
  styleUrls: ['./qcmobile-dialog.componet.scss'],
})
export class QcmobileDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<QcmobileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public authService: AuthService,
    public router: Router
  ) { }

  viewProducts(): void {
    window.location.href =
      window.location.origin +
      '#/home/quick-checkout/order?category=pharmacy&vendorKey=dayana&viewType=flyer';
    // this.authService.logout()
  }

  shareLink() {
    window.navigator.share({ text: window.location.href });
    this.authService.logout();
  }
}
