<button [matTooltip]="matTooltip" (click)="onClick()"  [style.height]="height" [style.width]="width" class="btn-{{theme}} {{class}} {{size}}" *ngIf="type === 'normal'"
    [disabled]="isDisabled">{{label}}</button>

<button  [matTooltip]="matTooltip" (click)="onClick()"  [style.height]="height" [style.width]="width" class="btn-{{theme}} {{class}} {{size}} btn-icon"
    *ngIf="type === 'icon'" [disabled]="isDisabled">
    <mat-icon [disabled]="isDisabled ? 'disabled' : ''">{{typeval}}</mat-icon>
</button>

<button [matTooltip]="matTooltip"  (click)="onClick()"  [style.height]="height" [style.width]="width" class="btn-{{theme}} {{class}} {{size}} btn-image"
    *ngIf="type === 'image'" [disabled]="isDisabled">
    <img width="24" height="24" src="{{typeval}}">{{label}}
</button>
