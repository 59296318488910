<h2 mat-dialog-title>{{ dialogData.heading }}</h2>
<mat-dialog-content class="mat-typography">
  <div [innerHTML]="dialogData.content"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    *ngIf="dialogData.showCancel"
    (click)="onActionClick()"
    mat-button
    mat-dialog-close
  >
    {{ dialogData.cancelBtnName }}
  </button>
  <button mat-button (click)="onActionClick(true)">
    {{ dialogData.actionBtnName }}
  </button>
</mat-dialog-actions>
