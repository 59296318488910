import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DadyinSliderComponent } from 'src/app/shared/widgets/dadyin-slider/dadyin-slider.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grid-view-product-card',
  templateUrl: './grid-view-product-card.component.html',
  styleUrls: ['./grid-view-product-card.component.scss'],
})
export class GridViewProductCardComponent implements OnInit {
  imgUrl = environment.imgUrl;
  @Input() product;
  @Input() i;
  @Input() ownerValue;
  @Input() showHideButtonLabelValue;
  @Input() hideAddToOrderValue;
  @Input() isSelfProductValue;
  @Input() isMyProductValue
  @Input() rating;
  @Input() buyingType;

  @Output() deleteProductFromOrderevent = new EventEmitter();
  @Output() viewDetailevent = new EventEmitter();
  @Output() viewCustomisedDetailevent =new EventEmitter();
  @Output() minusevent = new EventEmitter();
  @Output() plusevent = new EventEmitter();
  @Output() setQuantityevent = new EventEmitter();
  @Output() changeQuantityevent = new EventEmitter();
  @Output() addProductToOrderevent = new EventEmitter();


  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  hideAddToOrder(audit: any) {
    return this.hideAddToOrderValue;
  }

  showHideButtonLabel(product) {
    return this.showHideButtonLabelValue;
  }
  getOwner(audit) {
    return this.ownerValue;
  }

  deleteProductFromOrder(product) {
    this.deleteProductFromOrderevent.emit();
  }

  viewDetail(product, i, customise) {
    this.viewDetailevent.emit();
  }
  viewCustomisedDetail(product, i, customise) {
    this.viewCustomisedDetailevent.emit();
  }
  minus(i, quantity) {
    this.minusevent.emit();
  }

  plus(i, quantity) {
    this.plusevent.emit();
  }

  isSelfProduct(productDetails: any) {
    return this.isSelfProductValue;
  }

  openImageSlider(images: any, i) {
    this.dialog.open(DadyinSliderComponent, {
      data: { images: images, index: i },
    });
  }

  getRating(product) {
    return this.rating;
  }

  setQuantity(quantity,i) {
    this.setQuantityevent.emit({quantity:quantity,i:i});
  }
  changeQuantity(event,i) {
    this.changeQuantityevent.emit({event:event,i:i});
  }
  addProductToOrder(product) {
    this.addProductToOrderevent.emit();
  }

  isNoGenericPurchase(product:any) {
  return (product.productDetails?.isNoGenericPurchase == true) ? true : false
  }


}
