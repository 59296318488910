import { Component, OnInit, Input, ChangeDetectorRef, AfterContentChecked, Output, EventEmitter, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ControlContainer, FormControl, AbstractControl } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';

@Component({
    selector: 'dadyin-text',
    templateUrl: './dadyin-input.component.html',
    styleUrls: ['./dadyin-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DadyinInputComponent,
            multi: true
        }
    ]
})
export class DadyinInputComponent implements OnInit, ControlValueAccessor, AfterContentChecked {

    @Input() class = '';
    @Input() inputType = 'text';
    @Input() label = "";
    @Input() placeholder = "";
    @Input() isClearable = false;
    @Input() isDisabled = false;
    @Input() formControlName = '';
    @Input() customError: string = "";
    @Input() noPaste: boolean = false;
    @Input() isErrorClass: boolean = false;
    @Input() convertUpperCase: boolean = false;
    @Output('blurEvent') blurEvent = new EventEmitter();
    @Output('clickedEvent') clickedEvent = new EventEmitter();
    @Output('keyupEvent') keyupEvent = new EventEmitter();

    control!: FormControl;
    onChange: any = () => { };
    onTouched: any = () => { };
    pwToggle: boolean = false;
    validationRequired: boolean = false;

    @Output() selectedStateChange = new EventEmitter();

    @Input('value') _value = "";
    set value(val) {
        this._value = val;
        this.onChange(val);
        this.onTouched();
    }
    get value() {
        return this._value;
    }

    constructor(private commonService: CommonService, private controlContainer: ControlContainer, private cdr: ChangeDetectorRef) {
    }

    @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
        if (this.noPaste) {
            e.preventDefault();
        }
    }

    ngOnInit(): void {
        if (this.controlContainer.control && this.formControlName) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;

            // if (this.isDisabled) {
            //     this.control.disable();
            // }

            if (this.control.validator) {
                const validator = this.control.validator && this.control.validator({} as AbstractControl);
                this.validationRequired = validator && validator.required ? true : false;
                if (this.validationRequired) {
                }
            }
        }
    }

    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }

    onBlurEvent(event: any) {
        this.blurEvent.emit(event);
    }

    onClickedEvent(event: any) {
        this.clickedEvent.emit(event);
    }

    writeValue(event: any) {
        if (event) {
            if (typeof event === "string") {
                this.value = event;
                if (this.inputType === "textarea") {
                    this.value.replace(/\n\r?/g, '<br />');
                    this.value.trim();
                } else {
                    this.value.trim();
                }
            } else {
                const element = event.currentTarget as HTMLInputElement

                if (this.convertUpperCase) {
                    const startPos = element?.selectionStart;
                    const endPos = element?.selectionEnd;
                    element.value = element?.value.toUpperCase();
                    element?.setSelectionRange(startPos, endPos);
                }
                this.value = element?.value;
            }
            this.keyupEvent.emit(event);
        }
    }

    registerOnChange(fn: Function) {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function) {
        this.onTouched = fn;
    }

    getFieldErrorDesc(): string {
        if (this.control?.errors) {
            if (this.control.dirty || this.control.touched) {
                if (this.customError.length) {
                    return this.customError;
                }
                return this.commonService.getFieldErrorDesc(this.control);
            }
        }
        return "";
    }


    selectionChange(selectedOption: any) {
        this.selectedStateChange.emit(selectedOption);
    }
}
