import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of ,tap} from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache: Map<string, HttpResponse<any>> = new Map<string, HttpResponse<any>>();

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET') {
      // Bypass caching for non-GET requests
      return next.handle(request);
    }
    const cachedResponse: HttpResponse<any> = this.cache.get(request.url);

    if (cachedResponse && (request.url.includes('dadyin-api/meta') || request.url.includes("dadyin-api/relationaccounts/?filter=businessCategory:'VENDOR'") || request.url.includes("dadyin-api/relationaccounts/?filter=businessCategory:'CUSTOMER'") || request.url.includes("filter=businessCategory:'VENDOR'&filter=businessLine:'EXPORTER'") ) && (!request?.url.includes('noncache'))) {
   
      // A cached response exists for the request URL
      return of(cachedResponse.clone());
    }

    // No cached response, proceed with the request
    return next.handle(request).pipe(
      // Intercept the response and cache it
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.cache.set(request.url, event.clone());
        }
      })
    );
  }
}