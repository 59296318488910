<div style="position:relative;">

    <label for="" class="label-search">{{label}}</label>
    <div class="selected p-2">
        <div class="attribute b-none inner-box">
            <ng-container *ngIf="typeahead">
                <ng-container *ngIf="addTag">
                    <ng-select [maxSelectedItems]="maxSelectedItems"  class="search-select" [addTag]="addTagFn" [typeahead]="searchText"  [closeOnSelect]="true"
                        [hideSelected]="true" appendTo="body" [multiple]="true" [items]="fullItems" [disabled] = "disabled"
                        [formControl]="selectedItems" placeholder="Select" [bindValue]="bindValue" bindLabel="description" [minTermLength]="1"
                        [clearable]="false">
                    </ng-select>
                </ng-container>
                <ng-container *ngIf="!addTag">
                    <ng-select [maxSelectedItems]="maxSelectedItems"  class="search-select" [typeahead]="searchText"  [closeOnSelect]="true"
                        [hideSelected]="true" appendTo="body" [multiple]="true" [items]="fullItems" [disabled] = "disabled"
                        [formControl]="selectedItems" placeholder="Select" [bindValue]="bindValue" bindLabel="description" [minTermLength]="1"
                        [clearable]="false">
                    </ng-select>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!typeahead">
                <ng-select [maxSelectedItems]="maxSelectedItems"  class="search-select" [minTermLength]="1" [searchable]="true" [closeOnSelect]="true" [hideSelected]="true"
                    appendTo="body" [multiple]="true" [items]="fullItems" [formControl]="selectedItems" [disabled] = "disabled"
                    placeholder="Select" bindLabel="description" [bindValue]="bindValue" [clearable]="false">
                </ng-select>
            </ng-container>
        </div>

        <div class="d-flex flex-wrap w-100 selection-area-custom">
            <ng-container *ngIf="typeahead">
                <div [ngClass]="{'b-primary-all':highlightItems?.includes(item)}" (click)="onItemClick(item)" class="value bg-light b-all primary-text px-1" *ngFor="let item of selectedItems.value;let i =index">
                    {{item?.description ?? item}}
                    <i class="mx-1 material-icon" *ngIf ="!disabled"                      
                        (click)="remove(item,i)">cancel_outline
                    </i>
                </div>
            </ng-container>
            <ng-container *ngIf="!typeahead">
                <div [ngClass]="{'b-primary-all':highlightItems?.includes(item)}" (click)="onItemClick(item)"  class="value bg-light b-all primary-text px-1" *ngFor="let item of selectedItems.value;let i =index">
                    {{getName(item) ?? item}}
                    <i class="mx-1  material-icon" *ngIf ="!disabled"   
                        (click)="remove(item,i)">cancel_outline
                    </i>
                </div>
            </ng-container>
        </div>
    </div>
<br>
</div>


