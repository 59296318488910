<div class="datatable-container">
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="onSortChange($event)"
  >
    <ng-container
      *ngFor="let col of headers; let i = index"
      [matColumnDef]="col.prop"
    >
      <mat-header-cell
        [ngStyle]="{
          'min-width':
            col.prop == 'addressLine' || col.prop == 'description'
              ? '400px'
              : 'unset',
          'max-width': getMaxWidth(col)
        }"
        *matHeaderCellDef
        [disabled]="!col.sortable"
        mat-sort-header
        [ngClass]="{
          'custom-header': col?.dataType && col?.dataType == 'number'
        }"
        class="w-100"
      >
        <div class="d-flex flex-column w-100">
          <div
            [ngClass]="{
              'center-cell-header': col?.dataType && col?.dataType == 'number'
            }"
          >
            {{ headers[i].name }}
          </div>
          <div
            [ngClass]="{
              'center-cell-header': col?.dataType && col?.dataType == 'number'
            }"
          >
            {{this.tableData ? this.tableData[0]?.[headers[i]?.prop]?.userConversionUom : ''}}
          </div>
        </div>
      </mat-header-cell>
      <mat-cell
        [ngStyle]="{
          'min-width':
            col.prop == 'addressLine' || col.prop == 'description'
              ? '400px'
              : 'unset',
          'max-width': getMaxWidth(col)
        }"
        *matCellDef="let row; let rowIndex = index"
      >
        <div
          class="d-flex align-items-center mx-2"
          style="min-width: 50px"
          *ngIf="col.prop == 'description'"
        >
          <div
            *ngIf="row?.isQuickCheckoutEligible"
            class="primary-text d-flex align-items-center"
            (mouseenter)="$event.stopPropagation()"
          >
            <img
              width="20px"
              [matTooltip]="'Quick checkout Eligible'"
              src="../../../../assets/img/sidebar-icons/Fast Cart-1.png"
            />&nbsp;
          </div>
          <div
            *ngIf="row?.isCustomizable"
            class="primary-text d-flex align-items-center"
            (mouseenter)="$event.stopPropagation()"
          >
            <img
              width="20px"
              [matTooltip]="'Customizable'"
              src="../../../../assets/img/icons/Exclusive Product-Blue.png"
            />&nbsp;
          </div>
        </div>
        <div
          class="width-perc-95 d-flex align-items-center"
          [ngClass]="{
            'center-cell': col?.dataType == 'number' && col?.dataType,
            'plr-1': col?.dataType == 'number' && col?.dataType && col.sortable
          }"
        >
          <ng-template #tooltipinfo>
            <div class="row">
              <div class="col-12" *ngIf="col.prop == 'productCode'">
                <img
                  *ngIf="row?.productImageFileNames?.length > 0"
                  [src]="imgUrl + row?.productImageFileNames[0]"
                  width="300"
                  height="300"
                />
                <br />
                {{ row.productCode }}
              </div>
              <div class="col-12" *ngIf="col.prop == 'templateCode'">
                {{ row.description }}
              </div>
            </div>
          </ng-template>
          <ng-template #customtooltip>
            <div class="row customtooltip">
              <div class="col-md-12">
                <label class="tooltip-title">
                  <b> Owner: </b>
                </label>
                <!-- <span>{{row.audit.businessAccountId == businessId && userShow ? 'Dadyin' : 'Self'}}</span> -->
                <span>{{ row?.auditBusinessAccountName }}</span>
              </div>
              <div class="col-12" *ngIf="row?.createdBy == 'S'">
                <label> <b>Created Name: </b> </label
                ><span>{{ row.audit.createdByName }}</span>
                <br />
                <label> <b> Created Date: </b></label
                ><span>{{
                  row.audit.createdDate | date : "MM-dd-yyyy"
                }}</span>
                <br />
                <label> <b>Last Modified Name: </b></label
                ><span>{{ row.audit.lastModifiedByName }}</span
                ><br />
                <label> <b> Last Modified Date: </b></label
                ><span>{{
                  row.audit.lastModifiedDate | date : "MM-dd-yyyy"
                }}</span>
              </div>
            </div>
          </ng-template>
          <div
            *ngIf="col.prop == 'productCode'"
            class="audit me-1 text-center"
            [ngClass]="{
              'self-product': row?.createdBy == 'S',
              'third-product': row?.createdBy == 'T',
              'master-product': row?.createdBy == 'M'
            }"
            [attr.data-label]="col.name"
            [ngbTooltip]="customtooltip"
            placement="top"
            container="body"
          >
            <span>{{ row?.createdBy }}</span>
          </div>
          <ng-container *ngIf="!col.isLink">
            <ng-container *ngIf="col.type === 'serial_no'">
              <div [attr.data-label]="col.name" class="text-ellipsis">
                <span>{{ rowIndex + 1 }} </span>
              </div>
            </ng-container>

            <ng-template #icontooltip>
              <div class="profile-actions">
                <ng-container
                  *ngFor="
                    let action of actions.slice(2, actions?.length);
                    let i = index
                  "
                >
                  <div
                    *ngIf="check(action, row)"
                    class="d-flex align-items-center p-1 primary-text justify-content-end w-100"
                  >
                    <mat-icon
                      [matTooltip]="action.label"
                      class="edit-icon primary-text"
                      >{{ action.icon }}</mat-icon
                    >
                    <span
                      class="title-text mb-2"
                      (click)="
                        onActionArrayClick(action, rowIndex, row);
                        $event.stopPropagation()
                      "
                      >{{ action.label }}</span
                    >
                  </div>
                </ng-container>
              </div>
            </ng-template>

            <ng-container *ngIf="col.type === 'menu'">
              <div class="d-flex align-items-center justify-content-end w-100">
                <dadyin-button
                  [matTooltip]="
                    row.paymentEnabled
                      ? 'Pay now'
                      : 'Payment is not enabled by vendor'
                  "
                  [isDisabled]="!row.paymentEnabled"
                  *ngIf="row?.showPayNow"
                  (click)="$event.stopPropagation()"
                  type="image"
                  (clicked)="navigateToPayment(row)"
                  [typeval]="'assets/img/icons/Cash.png'"
                  [theme]="'borderless-primary'"
                >
                </dadyin-button>
                <ng-container *ngFor="let action of actions?.slice(0, 2)">
                  <div *ngIf="check(action, row)" class="actionicons">
                    <mat-icon
                      (click)="
                        onActionArrayClick(action, rowIndex, row);
                        $event.stopPropagation()
                      "
                      [matTooltip]="action.label"
                      class="edit-icon primary-text"
                      >{{ action.icon }}</mat-icon
                    >
                  </div>
                </ng-container>

                <mat-icon
                  *ngIf="actions.length > 2"
                  class="edit-icon"
                  triggers="manual"
                  #t="ngbTooltip"
                  (click)="t.open(); $event.stopPropagation()"
                  [ngbTooltip]="icontooltip"
                  placement="bottom-end"
                  >more_vert</mat-icon
                >

                <!-- related to customer/vendor listing for invite concept  -->
              </div>
            </ng-container>

            <ng-container *ngIf="!col.type && !col.isLink">
              <div
                container="body"
                placement="right"
                [ngbTooltip]="
                  col.prop == 'productCode' || col.prop == 'templateCode'
                    ? tooltipinfo
                    : null
                "
                class="d-flex align-items-center"
              >
                <span *ngIf="col.prop == 'transactionId'">
                  <mat-icon
                    matTooltip="Container Order"
                    *ngIf="row?.importLocalType == 'CONTAINER'"
                    class="primary-text"
                  >
                    anchor
                  </mat-icon>
                  <mat-icon
                    matTooltip="Local Order"
                    *ngIf="row?.importLocalType == 'LOCAL'"
                    class="primary-text"
                  >
                    location_on
                  </mat-icon>
                </span>
                <div
                  [attr.data-label]="col.name"
                  class="text-ellipsis"
                  [ngClass]="{
                    'text-end': col?.dataType && col?.dataType == 'number'
                  }"
                >
                  <span
                    *ngIf="
                      col?.dataType == 'number' &&
                      col?.decimal != false &&
                      col?.isCurrency
                    "
                  >
                    {{ row[col.prop] | numberFormatter : 3 }}
                  </span>
                  <span
                    *ngIf="
                      col?.dataType == 'number' &&
                      col?.decimal != false &&
                      col?.isPercent
                    "
                  >
                    {{ row[col.prop] | numberFormatter : 3 }}
                  </span>
                  <span
                    *ngIf="col?.dataType == 'number' && col?.decimal == false"
                  >
                    {{ row[col.prop] }}
                  </span>
                  <span *ngIf="col?.dataType != 'number'">
                    {{ row[col.prop] }}
                  </span>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="col.type == 'date'">
              <div [attr.data-label]="col.name" class="text-ellipsis">
                <span> {{ row[col.prop] | date : "dMMMyy" }} </span>
              </div>
            </ng-container>

            <ng-container *ngIf="col.type == 'uom'">
              <div [attr.data-label]="col.name" class="text-ellipsis">
                <!-- <span> {{row[col.prop]?.attributeValue  | numberFormatter:3}} </span> -->
                <span *ngIf="!col?.hideNumberFormatter">
                  {{ row[col.prop]?.attributeValue | numberFormatter : 2 }}
                </span>
                <span *ngIf="col?.hideNumberFormatter">
                  {{ row[col.prop]?.attributeValue | numberFormatter : 0 }}
                </span>
              </div>
            </ng-container>

      

            <ng-container *ngIf="col.type == 'audit'">
              <div
                class="audit text-center"
                [ngClass]="{
                  'self-product': row?.createdBy == 'S',
                  'third-product': row?.createdBy == 'T',
                  'master-product': row?.createdBy == 'M'
                }"
                [attr.data-label]="col.name"
                [ngbTooltip]="customtooltip"
                placement="right"
                container="body"
              >
                <span>{{ row?.createdBy }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="col.type === 'list_count'">
              <div [attr.data-label]="col.name">
                {{ row[col.prop] ? row[col.prop].length : "-" }}
              </div>
            </ng-container>
            <ng-container *ngIf="col.type === 'status'">
              <div class="status text-ellipsis" [attr.data-label]="col.name">
                <span>{{ row[col.prop] ? row[col.prop] : "-" }}</span>
              </div>
            </ng-container>
          </ng-container>
          <!-- Making table cell as link to open in new tab -->
          <ng-container *ngIf="col.isLink">
            <div
              container="body"
              placement="right"
              [ngbTooltip]="
                col.prop == 'productCode' || col.prop == 'templateCode'
                  ? tooltipinfo
                  : null
              "
              (click)="$event.stopPropagation()"
            >
              <a
                (click)="gotToLink(col.link + row[col.idKey])"
                [attr.data-label]="col.name"
                class="text-ellipsis"
                id="link-in-table-cell"
              >
                {{ row[col.prop] }}
              </a>
            </div>
          </ng-container>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      style="cursor: pointer"
      (click)="editRecord(rowIndex, row)"
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </mat-table>

  <mat-paginator
    #paginator
    [length]="pageConfig?.totalElements"
    [showTotalPages]="pageConfig?.totalPages"
    *ngIf="tableData?.length && pageConfig"
    [pageSizeOptions]="pageConfig.sizeOption ? pageConfig.sizeOption : []"
    (page)="onPageChange($event)"
    [pageSize]="pageConfig?.itemPerPage"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div>
<div *ngIf="!tableData || tableData.length === 0" class="no-data-found">
  <span class="mx-p-b-10"> No records found </span>
</div>
