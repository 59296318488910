<div class="dialog-container text-center p-4" style="overflow: hidden">
  <h3 mat-dialog-title class="my-2" *ngIf="data && data?.title">
    {{data?.title}}
  </h3>
  <div mat-dialog-actions class="justify-content-center">
    <button
      mat-raised-button
      class="qcmobile-btn bg-primary text-white"
      (click)="shareLink()"
      tabindex="-1"
    >
      Copy Link
    </button>
    <button
      mat-raised-button
      class="btn-secondary text-primary bg-secondary"
      (click)="viewProducts()"
      tabindex="-1"
    >
      View Products
    </button>
  </div>
</div>
