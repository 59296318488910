<div class="dialog-container">
  <h3 mat-dialog-title class="dialog-title">Add new conversion cost</h3>

  <form class="mat-dialog-content" [formGroup]="addCost">
    <div class="form">
      <mat-form-field>
        <mat-label>Cost Name</mat-label>
        <input
          type="text"
          matInput
          name="costName"
          formControlName="costName"
        />
      </mat-form-field>
    </div>
    <div class="form">
      <mat-form-field>
        <mat-label>Price</mat-label>
        <input type="text" matInput name="price" formControlName="price" />
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="justify-content-center">
      <button
        mat-raised-button
        color="primary"
        [mat-dialog-close]="1"
        (click)="save()"
      >
        Save
      </button>
      <button mat-raised-button color="warn" (click)="cancel()" tabindex="-1">
        Cancel
      </button>
    </div>
  </form>
</div>
