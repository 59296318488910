<div class="{{ class }}">
    <div  class="attribute one attr-b">
        <label for="">{{label}}</label>
        <div *ngIf="(inputType !== 'textarea') && (inputType !== 'date')  && (inputType !== 'datetime')"
            class="attribute-input">
            <input autocomplete="off" [readonly]="isDisabled" (change)="selectionChange($event.target.value);" [type]="inputType" [disabled]="isDisabled"
                (input)="writeValue($event)" [value]="value" (blur)="onBlurEvent($event)"
                (click)="onClickedEvent($event)" [name]="formControlName" [placeholder]="placeholder"
                [formControl]="control" [ngClass]="{'b-red':control?.invalid && control?.touched}"/>
            <i class="btn-password-toggle" *ngIf="passwordToggle && value"
                (click)="pwToggle = !pwToggle; inputType= pwToggle ? 'text' : 'password'">
                {{pwToggle ? 'visibility_off': 'visibility'}}</i>
            <i class="fa fa-close btn-clear" *ngIf="isClearable && value && (!isDisabled && !control.disabled)"
                (click)="this.value = ''"></i>
            <i class="{{customIcon}}" (click)="onClickedEvent($event)"></i>
        </div>
        <div *ngIf="inputType === 'textarea'" class="attribute-input">
            <textarea autocomplete="off" (change)="selectionChange($event.target.value);" class="input" [name]="formControlName"
                [rows]="rows" (input)="writeValue($event)" [value]="value" [formControl]="control"></textarea>
            <i class="fa fa-close btn-clear" *ngIf="isClearable && value && (!isDisabled && !control.disabled)"
                (click)="this.value = ''"></i>
        </div>
        <div *ngIf="inputType === 'date'" class="attribute-input">
            <input autocomplete="off" #dateinp [ngStyle]="{'color': dateinp.value ? 'black' : '#9bb5cf'}" [disabled]="isDisabled"  (change)="selectionChange($event.target.value);" type="date" class="input" [name]="formControlName"
                (input)="writeValue($event)" [formControl]="control">
        </div>
        <div *ngIf="inputType === 'datetime'" class="attribute-input">
            <input [disabled]="isDisabled" autocomplete="off" (change)="selectionChange($event.target.value);" type="datetime-local" class="input"
                [name]="formControlName" (input)="writeValue($event)" [formControl]="control">
        </div>

    </div>
</div>
