<div class="container p-3">
  <div class="font-size-16"> <b> Information: Customization Guidelines</b></div>

  <div class="font-size-14" >
    <p>
      <br /><span dir="ltr"
        >Welcome to the customization page! Here, you can upload multiple images
        such as your logo, design</span
      ><br /><span dir="ltr"
        >ideas, or even finished designs. Next to the upload button, you'll find
        a text box where you can</span
      ><br /><span dir="ltr"
        >provide additional instructions or details about your design
        preferences.</span
      ><br /><span dir="ltr"
        >For example, you might write: "Please add my store hours at the bottom
        (7:00 AM to 11:00 PM) and</span
      ><br /><span dir="ltr"
        >include my tagline: 'Quality You Can Trust.'"</span
      >
    </p>
    <p>
      <br /><span dir="ltr"
        >Please note that while this page allows you to share your ideas and
        upload materials, it doesn&rsquo;t offer</span
      ><br /><span dir="ltr"
        >tools for you to create the design yourself. Instead, our skilled
        in-house designers will take your</span
      ><br /><span dir="ltr"
        >provided images and text, understand your requirements, and craft the
        perfect design for you.</span
      ><br /><span dir="ltr"
        >Once your order is completed, we will email you the finalized design
        within 3-5 business days. We</span
      ><br /><span dir="ltr"
        >offer up to three revisions to ensure the design meets your
        approval.</span
      ><br /><span dir="ltr"
        >Feel free to provide as much detail as possible so we can deliver a
        design that meets your</span
      ><br /><span dir="ltr">expectations!</span>
    </p>
  </div>

  <div mat-dialog-actions class="justify-content-center">
    <button mat-raised-button class="bg-primary btn-primary" [mat-dialog-close]="1">
      Close
    </button>
  </div>
</div>
