import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  COST_ID,
  DENSITY_ID,
} from 'src/app/model/common/attribute-identifiers';

@Component({
  selector: 'add-new-product',
  templateUrl: './add-new-product-dialog.component.html',
  styleUrls: ['./add-new-product-dialog.component.scss'],
})
export class AddNewProductDialogComponent implements OnInit {
  addProduct: FormGroup;
  title: string;
  // Todo - Get data from api
  productNames = [
    { id: 1, description: 'Rice', usd: '50$', density: 0.23 },
    { id: 2, description: 'Salt', usd: '70$', density: 0.78 },
  ];

  constructor(
    public dialogRef: MatDialogRef<AddNewProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.addProduct = this._fb.group({
      productName: [''],
      cost: [{ value: '', disabled: true }],
      used: [''],
      density: [{ value: '', disabled: true }],
      avgDensity: [{ value: '', disabled: true }],
      costAddOn: [{ value: '', disabled: true }],
      waste: [''],
    });

    if (this.data?.type !== 'add') {
      this.title = 'Edit Product';
      const product = this.data?.values?.product.find(
        (attr) => attr.description === this.data?.values?.row?.productName
      );

      this.addProduct.controls.productName.setValue(product),
        this.addProduct.controls.cost.setValue(this.data?.values?.row?.usd),
        this.addProduct.controls.density.setValue(
          this.data?.values?.row?.density
        ),
        this.addProduct.controls.used.setValue(this.data?.values?.row?.used),
        this.addProduct.controls.waste.setValue(this.data?.values?.row?.waste),
        this.addProduct.controls.avgDensity.setValue(
          this.data?.values?.row?.avgDensity
        ),
        this.addProduct.controls.costAddOn.setValue(
          this.data?.values?.row?.costAddOn
        );
    } else {
      this.title = 'Add New Product';
    }
  }

  selectProduct(event: MatSelectChange): void {
    const cost = event?.value?.productAttributeValues?.find(
      (attr) => attr.attribute.id === COST_ID
    );
    const density = event?.value?.productAttributeValues?.find(
      (attr) => attr.attribute.id === DENSITY_ID
    );
    this.addProduct.controls.cost.setValue(cost?.attributeValue);
    this.addProduct.controls.density.setValue(density?.attributeValue);
  }

  calculateAvgCost(): void {
    const usedValue = parseFloat(this.addProduct.controls.used.value);
    const wasteValue = parseFloat(this.addProduct.controls.waste.value);
    const usdValue = this.addProduct.controls.cost.value
      ? parseFloat(this.addProduct.controls.cost.value)
      : 0;
    const used = usedValue ? usedValue : 0;
    const waste = wasteValue ? wasteValue : 0;
    const avgDensity = usdValue * ((used - waste) / 100);
    const costAddOn = usdValue * ((used + waste) / 100);
    this.addProduct.controls.avgDensity.setValue(Math.abs(avgDensity));
    this.addProduct.controls.costAddOn.setValue(Math.abs(costAddOn));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    const formData = {
      productName: this.addProduct.controls.productName.value.description,
      usd: this.addProduct.controls.cost.value,
      density: this.addProduct.controls.density.value,
      used: this.addProduct.controls.used.value,
      waste: this.addProduct.controls.waste.value,
      avgDensity: this.addProduct.controls.avgDensity.value,
      costAddOn: this.addProduct.controls.costAddOn.value,
    };
    this.dialogRef.close(formData);
  }
}
