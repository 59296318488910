<div class="dialog-container">
  <h3 mat-dialog-title>{{data?.title}}</h3>
  <div mat-dialog-actions class="justify-content-center">
    <button
      mat-raised-button
      color="warn"
      class="confirm-btn"
      [mat-dialog-close]="1"
    >
      Ok
    </button>
  </div>
</div>
