import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BusinessAccountService } from 'src/app/project/postlogin/business-account/business-account.service';
import { UomService } from 'src/app/service/uom.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
    imgUrl: any = environment.imgUrl
    @Input() set data(tableData) {
        this.tableData = tableData;
        this.loadTable();
    }
    @Input() pageConfig: any

    @Input() set filterData(data) {
        this.dataSource.filter = data ? data.trim().toLowerCase() : '';
    }
    @Input() set searchTerm(value) {
        this.dataSource.filter = (typeof value === 'string' ? value.trim().toLowerCase() : value) || '';
        this.getSearchValue = value;
    }
    @Output() actionClick = new EventEmitter();

    @Input() headers: any;
    @Input() actions: any = [{ label: '', icon: '' }];
    @Input() userShow: boolean = false;

    @Output() pageChange = new EventEmitter();
    @Output() sortChange = new EventEmitter();
    @Output() editData = new EventEmitter();
    @Output() uomChange = new EventEmitter();
    @ViewChild(MatSort, { static: false }) set sort(sortElm: MatSort) {
        if (this.dataSource) {
            this.dataSource.sort = sortElm;
        }
        this.sortTable = sortElm;
    }

    @ViewChild('paginator') paginator: MatPaginator;

    public config: any;
    public tableData: any;
    public businessId: any;
    public dataSource = new MatTableDataSource();
    public filter: any = [];
    public getSearchValue: any;
    public displayedColumns: any[]
    private sortTable: MatSort;




    customFilterPredicate(data: any, filterObj: any): boolean {
        let result = true;
        if (typeof filterObj === 'object') {
            for (const key in filterObj) {
                if (filterObj.hasOwnProperty(key)) {
                    const filterValue = filterObj[key];
                    if (key !== 'query') {
                        if (filterValue) {
                            let finalRes = data[key];
                            if (finalRes) {
                                result = result && finalRes.toString().toLowerCase().includes(filterValue.toLowerCase());
                            } else {
                                result = false;
                            }
                        }
                    } else {
                        const dataStr = Object.keys(data).reduce((acc, curKey) => {
                            let finalRes = data[curKey];
                            return acc + finalRes;
                        }, '');
                        result = result && dataStr.toLowerCase().includes(filterValue.toLowerCase());
                    }
                }
            }
        } else {
            const dataStr = Object.keys(data).reduce((acc, curKey) => {
                let finalRes = data[curKey];
                return acc + finalRes;
            }, '');
            result = dataStr.toLowerCase().includes(filterObj.toLowerCase());
        }
        return result;
    }

    constructor(public uomService: UomService, public router: Router, public businessAccountService: BusinessAccountService) { }

    ngOnInit(): void {
        this.displayedColumns = this.headers.map(col => col.prop);
        this.businessId = this.businessAccountService.currentBusinessAccountId

    }

    ngAfterViewInit(): void {
        this.sortTable.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

    }

    ngOnChanges() {
        if (this.dataSource) {
            this.dataSource.filter = (typeof this.getSearchValue === 'string' ?
                this.getSearchValue.trim().toLowerCase() : this.getSearchValue) || '';
        }
    }

    onActionArrayClick(action, rowIndex, row) {        
        this.actionClick.emit({ action, rowIndex, row });
    }

    loadTable() {

        this.dataSource = new MatTableDataSource(this.tableData);
        this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    }

    editRecord(index, data): void {
        this.editData.emit({ index, data });
    }

    onPageChange(data): void {

        this.pageChange.emit(data);
    }

    onUOMChange(data) {
        this.uomChange.emit(data);
    }

    onSortChange(sortCol: Sort): void {
        let sortData = null;
        sortData = sortCol;
        this.sortChange.emit(sortCol);
    }
    gotToLink(link: any) {
        this.router.navigateByUrl(link)

    }


    /// related to invite 

    check(action, row) {
        if (action.label == 'Resend') {
            return row?.showResendInviteButton ? true : false
        }
        if (action.label == 'Accept') {
            return row?.showAcceptRelationButton ? true : false
        }
        if (action.label == 'Reject') {
            return row?.showRejectRelationButton ? true : false
        }
        return true
    }


    getMaxWidth(col) {
      if(col.width) {
        return col.width
        }
        else {
            return  'unset'
        }
    }

    navigateToPayment(row) {
        this.router.navigateByUrl('home/quick-checkout/order/' + row.id + '?currentMainIndex=1');
    }

}
