<div class="dialog-container">
  <h3 mat-dialog-title class="dialog-title">{{ title }}</h3>

  <form class="mat-dialog-content" [formGroup]="addProduct">
    <div gdColumns="50% 50%" gdRow>
      <div class="form">
        <mat-form-field>
          <mat-label>Product Name</mat-label>
          <mat-select
            formControlName="productName"
            (selectionChange)="selectProduct($event)"
          >
            <mat-option
              *ngFor="let product of data?.values?.product"
              [value]="product"
            >
              {{ product?.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form">
        <mat-form-field>
          <mat-label>Weight/Cost</mat-label>
          <input type="text" matInput name="cost" formControlName="cost" />
        </mat-form-field>
      </div>
      <div class="form">
        <mat-form-field>
          <mat-label>Density</mat-label>
          <input
            type="text"
            matInput
            name="density"
            formControlName="density"
          />
        </mat-form-field>
      </div>
      <div class="form">
        <mat-form-field>
          <mat-label>% Used</mat-label>
          <input
            type="text"
            matInput
            name="used"
            formControlName="used"
            (blur)="calculateAvgCost()"
          />
        </mat-form-field>
      </div>
      <div class="form">
        <mat-form-field>
          <mat-label>Waste</mat-label>
          <input
            type="text"
            matInput
            name="waste"
            formControlName="waste"
            (blur)="calculateAvgCost()"
          />
        </mat-form-field>
      </div>
      <div class="form">
        <mat-form-field>
          <mat-label>Average Density</mat-label>
          <input
            type="text"
            matInput
            name="avgDensity"
            formControlName="avgDensity"
          />
        </mat-form-field>
      </div>
      <div class="form">
        <mat-form-field>
          <mat-label>Cost Addon</mat-label>
          <input
            type="text"
            matInput
            name="costAddOn"
            formControlName="costAddOn"
          />
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions class="justify-content-center">
      <button
        mat-raised-button
        color="primary"
        [mat-dialog-close]="1"
        (click)="save()"
      >
        Save
      </button>
      <button mat-raised-button color="warn" (click)="cancel()" tabindex="-1">
        Cancel
      </button>
    </div>
  </form>
</div>
