<div class="container p-3" style="max-height: 60vh;overflow: auto;">
  <div class="font-size-16 my-2"><b> Terms and Conditions </b></div>

  <div class="font-size-14">
    <p>
      <strong><span dir="ltr">1. User Agreement</span></strong>
    </p>
    <p>
      <span dir="ltr">- All users (vendors and customers) must agree to the Terms and
        Conditions before using the</span><br /><span dir="ltr">platform.</span><br /><span dir="ltr">- Users must be at
        least 18 years old to register and use the
        platform.</span>
    </p>
    <p>
      <strong><span dir="ltr">2. Prohibited Activities</span></strong>
    </p>
    <p>
      <br /><span dir="ltr">- Prohibition on the sale of illegal, counterfeit, or restricted
        products.</span><br /><span dir="ltr">- No sale of products that infringe on patents, trademarks, or other
        intellectual property rights.</span><br /><span dir="ltr">- Vendors must ensure that their products comply with
        all applicable
        laws and regulations.</span>
    </p>
    <p>
      <strong><span dir="ltr">3. Account Responsibilities</span></strong>
    </p>
    <p>
      <br /><span dir="ltr">- Users are responsible for maintaining the confidentiality of their
        account information.</span><br /><span dir="ltr">- The platform is not liable for any unauthorized access to
        user
        accounts.</span><br /><span dir="ltr">- Vendors are responsible for the accuracy of the product information
        they upload.</span>
    </p>
    <p>
      <br /><strong><span dir="ltr">4. Product Listings and Descriptions</span></strong>
    </p>
    <p>
      <span dir="ltr">- Vendors must provide accurate and complete descriptions of their
        products.</span><br /><span dir="ltr">- Misrepresentation or misleading descriptions are
        prohibited.</span><br /><span dir="ltr">- Vendors are responsible for updating the availability and pricing of
        their products.</span>
    </p>
    <p>
      <strong><span dir="ltr">5. Order and Payment Process</span></strong><br /><span dir="ltr">- All orders are subject
        to acceptance by the vendor.</span><br /><span dir="ltr">- Payment methods accepted by the
        platform.</span><br /><span dir="ltr">- Vendors must fulfill orders promptly and notify customers of any
        delays.</span>
    </p>
    <p>
      <strong><span dir="ltr">6. Shipping and Delivery</span></strong><br /><span dir="ltr">- Vendors are
        responsible for shipping products within the specified
        time frame.</span><br /><span dir="ltr">- Shipping costs and methods must be clearly stated.</span>
    </p>
    <p>
      <span dir="ltr">- The platform may offer tracking services but is not liable for delays
        or lost packages.</span>
    </p>
    <p>
      <strong><span dir="ltr">7. Return and Refund Policy</span></strong>
    </p>
    <p>
      <br /><span dir="ltr">- No return or refund for custom products.</span><br /><span dir="ltr">- A 7-day return
        policy for generic products, with the buyer
        responsible for return shipping</span><br /><span dir="ltr">costs.</span><br /><span dir="ltr">- Return products
        must be in their original packaging, unopened, and in
        good condition.</span><br /><span dir="ltr">- Sellers have the right to refuse a return if the packaging is
        open,
        damaged, or not in the</span><br /><span dir="ltr">original condition.</span><br /><span dir="ltr">- Refunds
        will be processed after the returned product is inspected and
        approved by the seller.</span><br /><span dir="ltr">- The platform may facilitate returns and refunds but is not
        liable for
        disputes between buyers</span><br /><span dir="ltr">and sellers.</span>
    </p>
    <p>
      <br /><strong><span dir="ltr">8. Intellectual Property</span></strong>
    </p>
    <p>
      <br /><span dir="ltr">- Users retain ownership of the content they upload but grant the
        platform a license to use,</span><br /><span dir="ltr">display, and distribute it.</span><br /><span dir="ltr">-
        The platform&rsquo;s trademarks, logos, and service marks are
        protected by intellectual property</span><br /><span dir="ltr">laws.</span>
    </p>
    <p>
      <br /><strong><span dir="ltr">9. Privacy Policy</span></strong>
    </p>
    <p>
      <br /><span dir="ltr">- Collection, use, and protection of user data.</span><br /><span dir="ltr">- Users consent
        to the platform collecting and using their data as
        described in the Privacy</span><br /><span dir="ltr">Policy.</span><br /><span dir="ltr">- Data sharing with
        third parties must be clearly disclosed.</span>
    </p>
    <p>
      <br /><strong><span dir="ltr">10. Dispute Resolution</span></strong>
    </p>
    <p>
      <br /><span dir="ltr">- Process for resolving disputes between users and the platform.</span><br /><span
        dir="ltr">- Mediation or arbitration as preferred methods of dispute
        resolution.</span><br /><span dir="ltr">- Jurisdiction and governing law.</span>
    </p>
    <div>
      <strong><span dir="ltr">11. Limitation of Liability</span></strong>
    </div>
    <div>
      <br /><span dir="ltr">- Disclaimer of warranties regarding the platform&rsquo;s performance
        and availability.</span><br /><span dir="ltr">- Limitation of the platform&rsquo;s liability for any damages
        arising
        from the use of the platform.</span><br /><span dir="ltr">- Indemnification clause requiring users to defend and
        indemnify the
        platform against claims.</span>
    </div>
    <div>
      <br /><strong><span dir="ltr">12. Termination of Accounts</span></strong>
    </div>
    <div>
      <br /><span dir="ltr">- The platform reserves the right to terminate user accounts for
        violations of the Terms and</span><br /><span dir="ltr">Conditions.</span><br /><span dir="ltr">- Users may
        terminate their accounts at any time.</span>
    </div>
    <div>
      <br /><strong><span dir="ltr">13. Amendments to Terms</span></strong>
    </div>
    <div>
      <br /><span dir="ltr">- The platform reserves the right to modify the Terms and Conditions at
        any time.</span><br /><span dir="ltr">- Users will be notified of changes and must agree to the updated terms
        to continue using the</span><br /><span dir="ltr">platform.</span>
    </div>
    <div>
      <br /><strong><span dir="ltr">14. Force Majeure</span></strong><br /><span dir="ltr">- The platform is not liable
        for delays or failures caused by events
        beyond its control, such as</span><br /><span dir="ltr">natural disasters, strikes, or technical issues.</span>
    </div>
    <div>
      <br /><strong><span dir="ltr">15</span><span dir="ltr">. Shipping Delays</span></strong><br /><span
        dir="ltr">&bull;</span><span dir="ltr">Sellers are not responsible for any delays in shipping due to
        third-party trucking,</span><br /><span dir="ltr">logistics, or vessel issues.</span><br /><span
        dir="ltr">&bull;</span><span dir="ltr">Estimated delivery times are provided for convenience and are not
        guaranteed.</span>
    </div>
    <div>
      <br /><strong><span dir="ltr">16. Product Variations</span></strong><br /><span dir="ltr">&bull;</span><span
        dir="ltr">Products may have a +/- 10% variation in weight, thickness, and quality
        count due to</span><br /><span dir="ltr">manufacturing processes.</span><br /><span dir="ltr">&bull;</span><span
        dir="ltr">These variations are considered within acceptable industry standards
        and do not</span><br /><span dir="ltr">constitute grounds for returns or refunds.</span>
    </div>
    <div>
      <br /><strong><span dir="ltr">17. Data Release in Legal Disputes</span></strong><br /><span
        dir="ltr">&bull;</span><span dir="ltr">The platform reserves the right to release user data to third parties
        or courts in the event</span><br /><span dir="ltr">of a legal dispute.</span>
    </div>
    <div>
      <span dir="ltr">&bull;</span><span dir="ltr">Users agree that their data may be disclosed as required by law or to
        protect the rights,</span><br /><span dir="ltr">property, or safety of the platform or others.</span>
    </div>
    <div>
      <br /><strong><span dir="ltr">18. Use of User Data</span></strong><br /><span dir="ltr">&bull;</span><span
        dir="ltr">The company will use data from all users to improve the
        platform&rsquo;s performance and for</span><br /><span dir="ltr">future enhancements.</span><br /><span
        dir="ltr">&bull;</span><span dir="ltr">Users consent to the collection and use of their data for these
        purposes.</span>
    </div>
    <div>
      <br /><strong><span dir="ltr">19. Miscellaneous**</span></strong>
    </div>
    <div>
      <br /><span dir="ltr">- Entire Agreement clause stating that the Terms and Conditions
        constitute the entire</span><br /><span dir="ltr">agreement between the parties.</span><br /><span dir="ltr">-
        Severability clause ensuring that if any part of the Terms is found
        to be unenforceable, the</span><br /><span dir="ltr">remaining provisions will remain in effect.</span>
    </div>
  </div>

  <div mat-dialog-actions class="justify-content-center">
    <button mat-raised-button  class="text-white bg-primary confirm-btn" (click)="agree()">
      I agree
    </button>
    <button mat-raised-button color="warn" [mat-dialog-close]="0">Close</button>
  </div>
</div>