<div style="height: 100%" class="card text-left b-light">
  <div class="img p-1 text-center" style="height: 200px">
    <swiper class="swiper-img" [pagination]="true" navigation="true">
      <ng-template
        swiperSlide
        *ngFor="
          let url of product?.productDetails?.productImageFileNames;
          let j = index
        "
      >
        <img
          class="cursor-zoom"
          (click)="
            openImageSlider(product?.productDetails?.productImageFileNames, j)
          "
          [src]="imgUrl + url"
          height="200px"
          width="100%"
          alt=""
        />
      </ng-template>
    </swiper>
  </div>
  <div class="d-flex align-items-center px-2 mt-2">
    <div class="w-100">
      <div
        class="font-size-14 description"
        [matTooltip]="product?.productDetails?.description"
      >
        ({{ product?.productDetails?.productCode }})
        {{ product?.productDetails?.description }}
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-between px-2">
    <div class="d-flex align-items-center justify-content-start">
      <div *ngIf="!isNoGenericPurchase(product)" style="text-wrap: nowrap">
        <ng-container *ngIf="['SKU', 'UNIT']?.includes(buyingType?.value)">
          <b
            *ngIf="product.productDetails?.skuThirdTier?.attributeValue"
            class="fw-bold font-size-14 primary-text"
          >
            {{
              isSelfProduct(product?.productDetails)
                ? "-"
                : product.productDetails?.skuThirdTier?.userConversionUom +
                  " " +
                  (product.productDetails?.skuThirdTier?.attributeValue
                    | numberFormatter : 3) +
                  " "
            }}
          </b>
        </ng-container>
        <ng-container *ngIf="['PALLET']?.includes(buyingType?.value)">
          <b
            *ngIf="product.productDetails?.palletTier?.attributeValue"
            class="fw-bold font-size-14 primary-text"
          >
            {{
              isSelfProduct(product?.productDetails)
                ? "-"
                : product.productDetails?.palletTier?.userConversionUom +
                  " " +
                  (product.productDetails?.palletTier?.attributeValue
                    | numberFormatter : 3) +
                  " "
            }}
          </b>
        </ng-container>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <div [ngbTooltip]="customtooltip">
        <span
          class="d-flex align-items-center justify-content-center mx-1"
          [isDisabled]="hideAddToOrder(product?.productDetails?.audit)"
          [matTooltip]="
            hideAddToOrder(product?.productDetails?.audit)
              ? 'To add this Product, select this vendor from above'
              : null
          "
          label="View Detail"
          [theme]="'primary'"
          [ngClass]="{
            'self-product': getOwner(product?.productDetails?.audit) == 'S',
            'third-product': getOwner(product?.productDetails?.audit) == 'T',
            'master-product': getOwner(product?.productDetails?.audit) == 'M'
          }"
        >
          {{ getOwner(product?.productDetails?.audit) }}
        </span>
      </div>
      <div style="height: 24px">
        <mat-icon style="height: 24px" class="star-icon-size primary-text"
          >star</mat-icon
        >
      </div>
      <div class="font-size-14">
        <b class="text-primary">
          {{ getRating(product?.productDetails?.productCode) }}
        </b>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-between px-2">
    <div class="d-flex align-items-center text-muted">
      <small *ngIf="!isNoGenericPurchase(product)">
        <ng-container
          matTooltip="Packaging will be finalised once the order is confirmed"
          *ngIf="product?.isCustomized"
        >
          1<sup>*</sup>/SKU
        </ng-container>
        <ng-container *ngIf="!product?.isCustomized">
          {{ product?.productDetails?.numberOfItems }}
          {{ product?.productDetails?.skuPackageContent }}/{{
            product?.productDetails?.skuPackageType
          }}
        </ng-container>
      </small>
    </div>
    <div class="d-flex align-items-center text-muted text-right b-top-primary">
      {{ 200 + (i + 7) * 13 }}
    </div>
  </div>
  <ng-container *ngIf="!isNoGenericPurchase(product)">
    <div class="b-light" style="width: auto;overflow: auto;">
      <table
        *ngIf="['SKU', 'UNIT']?.includes(buyingType?.value)"
        class="text-center quantity-table"
      >
        <tr class="primary-text bg-light">
          <td>Qty</td>
          <td class="text-muted cursor" (click)="setQuantity(5, this.i)">
            1-5
          </td>
          <td class="text-muted cursor" (click)="setQuantity(20, this.i)">
            6-20
          </td>
          <td class="text-muted cursor" (click)="setQuantity(21, this.i)">
            21+
          </td>
        </tr>
        <tr>
          <td class="primary-text">
            {{ product?.productDetails?.skuSecondTier?.userConversionUom }}
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.skuFirstTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.skuSecondTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.skuThirdTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
        </tr>
      </table>

      <table
        *ngIf="['PALLET']?.includes(buyingType?.value)"
        class="text-center quantity-table"
      >
        <tr class="primary-text bg-light">
          <td>Qty</td>
          <td class="text-muted cursor" (click)="setQuantity(21, this.i)">
            21+
          </td>
          <td class="text-muted cursor">
            Pallet ( {{ product?.productDetails?.numberInPallet }}
            {{ product?.productDetails?.skuPackageType }} )
          </td>
        </tr>
        <tr>
          <td class="primary-text">
            {{ product?.productDetails?.palletTier?.userConversionUom }}
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.skuThirdTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.palletTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
        </tr>
      </table>
      <table
        *ngIf="
          [
            'CONTAINER_40_FT',
            'CONTAINER_20_FT',
            'CONTAINER_40_FT_HQ'
          ]?.includes(buyingType?.value)
        "
        class="text-center quantity-table"
      >
        <tr class="primary-text bg-light">
          <td>Qty</td>
          <td class="text-muted cursor">CONTAINER 20FT</td>
          <td class="text-muted cursor">CONTAINER 40FT</td>
        </tr>
        <tr>
          <td class="primary-text">
            {{ product?.productDetails?.containerTier?.userConversionUom }}
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.containerTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
          <td>
            <b
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
              class="mx-2"
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (product?.productDetails?.containerTier?.attributeValue
                    | numberFormatter : 3)
              }}</b
            >
          </td>
        </tr>
      </table>
    </div>
  </ng-container>

  <div
    class="primary-text"
    style="height: 115px"
    *ngIf="isNoGenericPurchase(product)"
  >
    <!-- This product is tailored specifically for customization. -->

    <div *ngIf="product?.productDetails?.tierPricingDetail" class="b-light">
      <table class="text-center quantity-table">
        <tr class="primary-text bg-light">
          <td>SKU Qty</td>
          <ng-container
            *ngFor="
              let tierPricing of product?.productDetails?.tierPricingDetail
                ?.tierPricingCustomization | sortNumberProperty : 'minimumQuantity';
              let k = index
            "
          >
            <td *ngIf="k < 3" class="text-muted">
              {{ tierPricing.minimumQuantity }}
            </td>
          </ng-container>

          <td class="text-muted">ORDER DELIVERY</td>
        </tr>
        <tr
          class="b-bottom-secondary"
          *ngFor="
            let deliveryPricing of product?.productDetails?.tierPricingDetail
              ?.tierPricingCustomization[0]?.deliveryPricing;
            let i = index
          "
        >
          <td class="text-primary" rowspan="2" *ngIf="i == 0">SKU Price</td>
          <ng-container
            *ngFor="
              let tierPricing of product?.productDetails?.tierPricingDetail
                ?.tierPricingCustomization | sortNumberProperty : 'minimumQuantity';
              let k = index
            "
          >
            <td
              *ngIf="k < 3"
              class="width-100"
              [matTooltip]="
                isSelfProduct(product?.productDetails)
                  ? 'Please Request Vendor For Pricing'
                  : ''
              "
            >
              {{
                isSelfProduct(product?.productDetails)
                  ? "-"
                  : (tierPricing?.deliveryPricing[i]?.deliveryCost
                      ?.attributeValue | numberFormatter : 3)
              }}
            </td>
          </ng-container>

          <td class="width-100">
            {{
              purchaseOrderService.daysFreightMap[deliveryPricing?.numberOfDays]
            }}
            ({{ deliveryPricing?.numberOfDays }} days)
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div
    *ngIf="!isNoGenericPurchase(product)"
    style="height: 60px"
    class="b-light bg-light primary-text mt-2 mx-2"
  >
    <div class="row mx-auto" style="text-wrap: nowrap">
      <div class="col-6 font-size-10">
        <b>SKU Qty</b> <br />
        <div class="d-flex bg-white my-1">
          <dadyin-button
            [theme]="'borderless-primary'"
            type="image"
            [typeval]="'assets/img/icons/Minus.svg'"
            (clicked)="minus(this.i, product.quantity)"
          ></dadyin-button>
          <input
            type="number"
            min="1"
            (change)="changeQuantity($event, i)"
            required
            class="text-center w-100 b-none"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="product.quantity"
          />
          <dadyin-button
            [theme]="'borderless-primary'"
            type="image"
            [typeval]="'assets/img/icons/added.svg'"
            (clicked)="plus(this.i, product.quantity)"
          ></dadyin-button>
        </div>
      </div>
      <div class="col-6 font-size-10 text-right">
        <b>Order Value</b><br />
        <div class="my-2">
          <div
            class="price-text font-size-14"
            [matTooltip]="
              isSelfProduct(product?.productDetails)
                ? 'Please Request Vendor For Pricing'
                : ''
            "
          >
            {{
              isSelfProduct(product?.productDetails)
                ? "-"
                : product?.totalCost?.userConversionUom +
                  " " +
                  (product?.totalCost?.attributeValue | numberFormatter : 3)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="height: 50px; column-gap: 5px"
    class="d-flex align-items-center justify-content-around p-2"
  >
    <dadyin-button
      class="w-100"
      [isDisabled]="hideAddToOrder(product?.productDetails?.audit)"
      (clicked)="viewDetail(product, this.i, product.isCustomized)"
      [label]="isNoGenericPurchase(product) ? 'More Tiers' : 'View Details'"
      [theme]="isNoGenericPurchase(product) ? 'primary' : 'secondary'"
      [matTooltip]="
        hideAddToOrder(product?.productDetails?.audit)
          ? 'To add this Product, select this vendor from above'
          : null
      "
    >
    </dadyin-button>
    <dadyin-button
      *ngIf="!isNoGenericPurchase(product)"
      class="w-100"
      (clicked)="addProductToOrder(product)"
      [isDisabled]="hideAddToOrder(product?.productDetails?.audit)"
      [matTooltip]="
        hideAddToOrder(product?.productDetails?.audit)
          ? 'To add this Product, select this vendor from above'
          : null
      "
      [label]="showHideButtonLabel(product) ? 'Update Order' : 'Add to Order'"
      [theme]="showHideButtonLabel(product) ? 'secondary' : 'primary'"
    >
    </dadyin-button>

    <dadyin-button
      *ngIf="showHideButtonLabel(product)"
      (clicked)="deleteProductFromOrder(product)"
      [typeval]="'delete_outline'"
      type="icon"
      [theme]="'borderless-danger'"
    >
    </dadyin-button>
  </div>

  <dadyin-button
    matTooltip="Customize this product"
    class="customisable bg-secondary b-light"
    *ngIf="product?.productDetails?.isCustomizable"
    (clicked)="viewCustomisedDetail(product, this.i, true)"
    type="image"
    [typeval]="
      isMyProductValue
        ? 'assets/img/icons/myproduct.svg'
        : 'assets/img/icons/Exclusive Product-Blue.png'
    "
    [label]="isMyProductValue ? 'My Product' : 'Customizable'"
    [theme]="'borderless-primary'"
  >
  </dadyin-button>
</div>

<ng-template #customtooltip>
  <div class="row customtooltip">
    <div class="col-md-12">
      <label class="tooltip-title">
        <b> Owner: </b>
      </label>
      <span>{{ product?.productDetails?.auditBusinessAccountName }}</span>
    </div>
    <div class="col-12" *ngIf="getOwner(product?.productDetails?.audit) == 'S'">
      <label> <b> Created Date: </b></label
      ><span>{{
        product?.productDetails?.audit.createdDate | date : "MM-dd-yyyy"
      }}</span>
      <br />
      <label> <b>Last Modified Name: </b></label
      ><span>{{ product?.productDetails?.audit.lastModifiedByName }}</span
      ><br />
      <label> <b> Last Modified Date: </b></label
      ><span>{{
        product?.productDetails?.audit.lastModifiedDate | date : "MM-dd-yyyy"
      }}</span>
    </div>
  </div>
</ng-template>
