<div class="dialog-container">
  <h3 mat-dialog-title class="dialog-title">Add attributes</h3>
  <mat-form-field>
    <mat-label>Attributes</mat-label>
    <mat-select (selectionChange)="selectProductType($event)">
      <mat-option *ngFor="let attribute of data" [value]="attribute">
        {{ attribute?.description }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div mat-dialog-actions class="action" class="justify-content-center">
    <button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="1"
      (click)="save()"
    >
      Save
    </button>
    <button mat-raised-button color="warn" (click)="cancel()" tabindex="-1">
      Cancel
    </button>
  </div>
</div>
